// gatsby-browser.js
import "./src/styles/global.css"

//import bootstrap css and icons
//import bootstrap bootstrap and bootstrap icons
//import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";

// import highlight.js and custom themes
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css'; // choose your theme

export const onRouteUpdate = () => {
  hljs.highlightAll(); // highlights all <code> blocks with language hints
};
